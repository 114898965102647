<template>
  <router-link :title="text" :to="link">

    <h1>Leave messages for people to find</h1>

    <div class="image" :style="style"></div>

  </router-link>
</template>

<script>
export default {
  name: 'HomeBanner',

  data() {
    return {
      text: 'Notes',
      link: 'notes',
      image: require( '@/assets/images/navigation/banner.png' )
    }
  },

  computed: {
    style() {
      const image = this.image
      if ( !image ) return null
      return `background-image: url(${ image });`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

a {
  background-color: $off-white;
  @include box-shadow( $shadow );
  width: 100%;
  padding: 20px;
  cursor: pointer;
  position: relative;
  margin-bottom: 16px;
  height: 180px;

  @media ( max-width: $mobile ) {
    display: none;
  }

  h1 {
    color: $dark-grey;
    font-size: 24px;
    position: relative;
    text-align: left;
    width: 240px;
    z-index: 9;
    padding: 4px;

    $c: rgba( $white, 0.8 );
    $bs: inset 0 0 16px 36px $c, 0 0 16px 8px $c;
    @include box-shadow( $bs );
  }

  &:hover {
    .image {
      opacity: 1;
    }
  }

  .image {
    @include image-center;
    @include full-stretch;
    opacity: $opacity-low;
    transform-origin: center center;
    transition: transform 0.05s ease-in-out;
  }
}
</style>
