<template>
  <div class="home page">

    <!-- Newest feature -->
    <h2 class="bk">Newest Feature</h2>
    <Banner class="banner"/>
    <Tile class="tile" :link="'notes'"/>

    <!-- Top features -->
    <h2 class="bk">Top Features</h2>
    <ul>
      <Tile v-for="( link, index ) in links" :key="index" :link="link"/>
    </ul>

  </div>
</template>

<script>
import Banner from '@/components/home/Banner.vue'
import Tile from '@/components/hub/Tile.vue'

export default {
  name: 'Home',

  data() {
    return {
      links: [ 'feed', 'chatroom', 'friends', 'shop' ]
    }
  },

  metaInfo() {
    return {
      title: this.$router.options.title( this.$route )
    }
  },

  components: {
    Banner,
    Tile
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.home {
  padding: $page-padding;
  text-align: left;

  h2 {
    color: $dark-grey;
    font-size: 18px;
    position: relative;
    text-align: left;
    display: inline-block;
    margin: 0 0 6px -1px;
  }

  .banner {
    @media ( max-width: $mobile ) {
      display: none;
    }
  }

  .tile {
    display: none;

    @media ( max-width: $mobile ) {
      display: block;
      margin-bottom: 16px;
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat( 2, 1fr );
    grid-gap: $page-padding;

    @media ( max-width: $mobile ) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
